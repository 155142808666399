import React from 'react'
import styles from './User.module.css'
import {FaUserTie, FaPhoneAlt, FaEdit} from 'react-icons/fa'
import {IoMdMail} from 'react-icons/io'
import {BsFillCalendarDateFill, BsTrashFill} from 'react-icons/bs'
import { deleteUser } from '../../redux/actions'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function User({user}) {

  const navigate = useNavigate()
  const {currentUser} = useSelector(state => state)

  const handleEdit = () => {
    navigate(`editar-usuario/${user.uid}`)
  }

  const handleAlertDelete = async() => {
    const confirmAction = window.confirm('¿Seguro que desea eliminar este usuario?')
    if(confirmAction) {
      await handleDelete()
    }
  }

  const handleDelete = async() => {
    if(user.email !== currentUser.email) {
      await deleteUser(user.uid)
    } else {
      alert('No puede eliminar su cuenta')
    }
  }

  return (
    <div className={styles.User}>
      <div className={styles.info}>
        <div className={styles.item}>
          <FaUserTie />
          <p>{user.name} {user.lastname}</p>
        </div>
        <div className={styles.item}>
          <IoMdMail />
          <p>{user.email}</p>
        </div>
        <div className={styles.item}>
          <FaPhoneAlt />
          <p>{user.phoneNumber}</p>
        </div>
        <div className={styles.item}>
          <BsFillCalendarDateFill />
          <p>{new Date(user.createdAt).toLocaleString()}</p>
        </div>
      </div>
      <div className={styles.buttons}>
        <BsTrashFill onClick={handleAlertDelete} />
        <FaEdit onClick={handleEdit} />
      </div>
    </div>
  )
}

export default User