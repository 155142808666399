import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { editUser, getUserById } from '../../redux/actions'
import Loader from '../Loader/Loader'
import styles from './EditUser.module.css'
import Input from '../Input/Input'
import Title from '../Title/Title'

function EditUser() {
  const [isLoading, setIsLoading] = useState(true)
  const [isUser, setIsUser] = useState({
    uid: '',
    name: '',
    lastname: '',
    email: '', 
    phoneNumber: '',
    createdAt: '',
    rol: ''
  })

  const params = useParams()
  const dispatch = useDispatch()
  const {user} = useSelector(state => state)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getUserById(params.id))
  }, []) // eslint-disable-line

  useEffect(() => {
    setIsLoading(true)
    if(user && user.uid === params.id) {
      setIsUser({
        uid: user.uid,
        name: user.name, 
        lastname: user.lastname, 
        email: user.email, 
        phoneNumber: user.phoneNumber, 
        createdAt: user.createdAt,
        rol: user.rol
      })
      setIsLoading(false)
    }
  }, [user]) // eslint-disable-line

  const handleEdit = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    const res = await editUser(params.id, isUser)
    setIsLoading(false)
    if(res.status === 200) {
      alert('Datos actualizados correctamente')
      setIsUser({uid: '',name: '', lastname: '', email: '', phoneNumber: '', createdAt: '', rol: ''})
      navigate('/admin')
    } else {
      alert('Ocurrió un error, intente de nuevo')
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setIsUser({...isUser, [name]: value})
  }

  const handleCancel = () => {
    navigate('/admin')
  }

  return (
    <div className={styles.EditUser}>
      {isLoading ? <Loader /> : 
        <div >
          <Title title='Editar Usuario' />
          <form onSubmit={handleEdit}>
            <Input
              styl='dark' 
              placeholder='Nombre' 
              name='name'
              value={isUser.name}
              required={true}
              type='text'
              handleChange={handleChange} />
            <Input
              styl='dark' 
              placeholder='Apellido' 
              name='lastname'
              value={isUser.lastname}
              required={true}
              type='text'
              handleChange={handleChange} />
            <Input
              styl='dark' 
              placeholder='Correo' 
              name='email'
              value={isUser.email}
              required={true}
              type='email'
              disabled={true}
              handleChange={handleChange} />
            <Input
              styl='dark' 
              placeholder='Fecha de creación' 
              name='createdAt'
              value={new Date(isUser.createdAt).toLocaleString()}
              required={true}
              type='text'
              disabled={true}
              handleChange={handleChange} />
            <Input
              styl='dark' 
              placeholder='Teléfono' 
              name='phoneNumber'
              value={isUser.phoneNumber}
              required={true}
              type='number'
              handleChange={handleChange} />
            <div className={styles.buttonsEdit}>
              <Input 
                styl='dark' 
                value={'Actualizar'}
                type='submit' />
              <button onClick={handleCancel} className={styles.cancelBtn}>Cancelar</button>
            </div>
          </form>
        </div>
      }
    </div>
  )
}

export default EditUser