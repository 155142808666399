import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './Users.module.css'
import Title from '../Title/Title'
import User from '../User/User'

function Users() {

  const {users} = useSelector(state => state)
  const navigate = useNavigate()

  const handleGoToAdd = () => {
    navigate('agregar-usuario')
  }

  return (
    <div className={styles.Users}>
      <button onClick={handleGoToAdd} className={styles.addBtn}>Agregar usuario</button>
      <div className={styles.cardsCtn}>
        <Title title='Usuarios' />
        {users.map((el, i) => (
          <User key={i} user={el} />
        ))}
      </div>
    </div>
  )
}

export default Users