import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCN2rld_tEqKkcTWb4CcE7BDF5Zqxs6c9Y',
  authDomain: 'cotizador-integrarcctv.firebaseapp.com',
  databaseURL: 'https://cotizador-integrarcctv-default-rtdb.firebaseio.com',
  projectId: 'cotizador-integrarcctv',
  storageBucket: 'cotizador-integrarcctv.appspot.com',
  messagingSenderId: '1083874202790',
  appId: '1:1083874202790:web:cc2c725ef76cee58cf1067'
}

const app = initializeApp(firebaseConfig)
export const database = getDatabase(app)
export const auth = getAuth(app)