import React, { useState } from 'react'
import styles from './Login.module.css'
import Input from '../../components/Input/Input'
import { IoEye, IoEyeOff } from 'react-icons/io5'
import Loader from '../../components/Loader/Loader'
import logo from '../../assets/logo.png'
import { login } from '../../redux/actions'
import { useEffect } from 'react'

function Login() {
  const [isLoading, setIsLoading] = useState(true)
  const [credentials, setCredentials] = useState({email: '', password: ''})
  const [showPass, setShowPass] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000)
    return () => clearTimeout(timer)
  }, [])

  const handleChange = (e) => {
    const {name, value} = e.target
    setCredentials({...credentials, [name]: value})
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const res = await login(credentials.email, credentials.password)
    if(res.errorCode) {
      alert(res.errorMessage)
    }
  }

  return (
    <div className={styles.container}>
      {isLoading ? <Loader size={100} /> :
      <div className={styles.formContainer}>
        <div className={styles.imageContainer}>
          <img src={logo} alt="logo integrar cctv" />
        </div>
        <form onSubmit={handleSubmit}>
          <h4>Panel de administración</h4>
          <h2>Inicio de sesión</h2>
          <Input 
            styl='light' 
            type='email' 
            placeholder='Ingrese su correo...'
            name='email'
            value={credentials.email}
            required={true}
            handleChange={handleChange} />
          <div className={styles.passContainer}>
            <Input 
              styl='light' 
              type={showPass ? 'text' : 'password' }
              placeholder='Ingrese su contraseña...'
              name='password'
              value={credentials.password}
              required={true}
              handleChange={handleChange} />
            {showPass ? <IoEyeOff onClick={() => setShowPass(!showPass)} /> : 
              <IoEye onClick={() => setShowPass(!showPass)} />
            }
          </div>
          <Input 
            styl='dark' 
            type='submit' 
            value='iniciar sesión' />
        </form>
      </div>
      }
    </div>
  )
}

export default Login