// const consumer_key = process.env.REACT_APP_CONSUMER_KEY
const consumer_key = 'ck_4180668352c21a700b8e933028c055d2295b4b8e'
// const consumer_secret = process.env.REACT_APP_CONSUMER_SECRET
const consumer_secret = 'cs_4f2bb7da0671f598c47c907cdfcc2a1086ba5172'
const authorizationBasic = window.btoa(`${consumer_key}:${consumer_secret}`)

export const config = {
  "headers": {
    "Authorization": `Basic ${authorizationBasic}`
  }
}