import axios from "axios"
import { auth, database } from "../firebaseConfig"
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth"
import { child, get, onValue, push, ref, remove, serverTimestamp, set } from "firebase/database"
import { config } from "../utils/wooAuth"
// const urlWoo = process.env.REACT_APP_URL_BASE_WOO
const urlWoo = 'https://integrarcctv.com/wp-json/wc/v3'


//USUARIOS
//autenticacion
export const login = async(email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return {status: 200, message: 'Inicio de sesión correcto'}
  } catch (error) {
    const errorCode = error.code
    const errorMessage = error.message.split(':')[1]
    if(errorMessage.includes('wrong-password')) {
      return {errorCode, errorMessage: 'Contraseña incorrecta'}
    } else if(errorMessage.includes('Access to this account has been temporarily disabled due to many failed login attempts')) {
      return {errorCode, errorMessage: 'Demasiados intentos fallidos, por favor intente más tarde'}
    } else {
      return {errorCode, errorMessage}
    }
  }
}
//verificar usuario autenticado
export const isLogged = () => {
  return (dispacth) => {
    onAuthStateChanged(auth, user => {
      if(user) {
        const userRef = ref(database, `users/${user.uid}`)
        onValue(userRef, snap => {
          const userVal = snap.val()
          dispacth({
            type: 'GET_CURRENT_USER',
            payload: userVal
          })
        })
      } else {
        dispacth({
          type: 'GET_CURRENT_USER',
          payload: null
        })
      }
    })
  }
}
//cerrar sesion
export const logout = async() => {
  await signOut(auth)
}
//crear usuario
export const addUser = async(email, password, name, lastname, phoneNumber) => {
  try {
    const newUser = await createUserWithEmailAndPassword(auth, email, password)
    const usersRef = ref(database, 'users/')
    const getUsers = await get(usersRef)
    const users = getUsers.val()
    let isUser = false
    for(let item in users) {
      if(users[item].email === email) {
        isUser = true
      }
    }
    if(isUser) {
      return {errorMessage: 'Ya existe este usuario'}
    } else {
      const createdAt = serverTimestamp()
      const data = {uid: newUser.user.uid, name, lastname, email, password, phoneNumber, createdAt, rol: 'user'}
      const userRef = ref(database, `users/${newUser.user.uid}`)
      await set(userRef, data)
      return {status: 200, message: 'Usuario creado con éxito'}
    }
  } catch (error) {
    const errorCode = error.code
    const errorMessage = error.message
    return {errorCode, errorMessage}
  }
}
// obtener usuarios
export const getUsers = () => {
  return async(dispatch) => {
    try {
      const usersRef = ref(database, `users/`)
      onValue(usersRef, snap => {
        const value = snap.val()
        let arr = []
        for(let item in value) {
          arr.push(value[item])
        }
        dispatch({
          type: 'GET_ALL_USERS',
          payload: arr
        })
      })
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      return {errorCode, errorMessage}
    }
  }
}
// obtener usuario por id
export const getUserById = (userId) => {
  return async(dispacth) => {
    try {
      const userRef = ref(database, `users/${userId}`)
      onValue(userRef, snap => {
        dispacth({
          type: 'GET_USER_BY_ID',
          payload: snap.val()
        })
      })
    } catch (error) {console.log(error)}
  }
}
//editar usuario
export const editUser = async(userId, data) => {
  try {
    const userRef = ref(database, `users/${userId}`)
    await set(userRef, data)
    return {status: 200, message: 'Usuario creado con éxito'}
  } catch (error) {console.log(error)}
}
//eliminar usuario
export const deleteUser = async(id) => {
  try {
    const userRef = ref(database, `users/${id}`)
    remove(userRef)
  } catch (error) {console.log(error)}
}
//cambiar contraseña
export const changePass = async(currentPass, newPass) => {
  try {
    const user = auth.currentUser
    const re = await login(user.email, currentPass)
    if(re.status === 200) {
      await updatePassword(user, newPass)
      return {status: 200, message: 'Contraseña cambiada con éxito'}
    } else {
      return {errorMessage: 'Error La contraseña actual es incorrecta'}
    }
  } catch (error) {
    const errorCode = error.code
    const errorMessage = error.message
    return {errorCode, errorMessage}
  }
}






//obtener todos los productos de una categoria - Woocommerce
export const getProductsBySearch = (string, tag) => {
  return async(dispacth) => {
    try {
      const res = await axios.get(`${urlWoo}/products?tag=${tag}&search=${string}&per_page=100`, config)
      const newArr = res.data.map(el => {
        const obj = {
          categories: el.categories,
          brand: el.categories[0].id !== 16 && el.categories[0].id !== 19 ? el.name.split(' ').pop() : null,
          name: el.name,
          permalink: el.permalink,
          short_description: el.short_description,
          tags: el.tags,
          images: el.images,
          id: el.id,
          price: el.price,
          regular_price: el.regular_price,
          sale_price: el.sale_price
        }
        return obj
      }).filter(el => el.categories[0].id !== 80)
      dispacth({
        type: 'PRODUCTS_SEARCH',
        payload: {search: newArr, string, tag}
      })
    } catch (error) {console.log(error)}
  }
}

//filtrar productos ya añadidos
const publishedProducts = async(page = 1) => {
  try {
    const products = await axios.get(`${urlWoo}/products?tag=565&per_page=100&page=${page}&orderby=title&order=asc`, config)
    return products.data
  } catch (error) {console.log(error)}
}

// actualizar los productos buscados al agregar alguno al cotizador
export const updateSearch = (id) => {
  return (dispacth) => {
    dispacth({
      type: 'PRODUCTS_SEARCH_UPDATE',
      payload: id
    })
  }
}

//RESETA LA BUSQUEDA
export const resetSearch = () => dispacth => {
    dispacth({type: 'RESET_SEARCH'})
}


//obtener los productos en el cotizador
export const getCotProducts = (page) => {
  return async(dispacth) => {
    const data = await publishedProducts(page)
    dispacth({
      type: 'PRODUCTS',
      payload: data
    })
  }
}

//obtener los productos en el cotizador
export const getCotProductsByCat = (page = 1, cat) => {
  return async(dispacth) => {
    try {
      const products = await axios.get(`${urlWoo}/products?category=${cat}&tag=565&per_page=20&page=${page}&orderby=title&order=asc`, config)
      dispacth({
        type: 'PRODUCTS_BY_CAT',
        payload: products.data
      })
    } catch (error) {console.log(error)}
  }
}


//porcentaje de descuento
export const percent = (perc) => {
  return (dispacth) => {
    dispacth({
      type: 'PERCENTAGE',
      payload: perc
    })    
  }
}

//actualizar producto
export const updateAddProduct = async(id, data) => {
  try {
    await axios.put(`${urlWoo}/products/${id}`, data, config)
  } catch (error) {console.log(error)}
}


//paginado
export const pagination = (page) => {
  return (dispacth) => {
    dispacth({
      type: 'PAGINATION',
      payload: page
    })
  }
}

