import React, { useState } from 'react'
import styles from './CardCot.module.css'
import { BsTrashFill } from "react-icons/bs"
import { IoMdArrowDropdown } from "react-icons/io"
import { FaEdit, FaSave} from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import { getCotProducts, getCotProductsByCat, getProductsBySearch, percent, resetSearch, updateAddProduct } from '../../redux/actions'
import Input from '../Input/Input'
import Percent from '../Percent/Percent'

function CardCot({product, setIsLoading, isSelect}) {
  const [viewDescription, setViewDescription] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [newPrice, setNewPrice] = useState(0)

  const dispatch = useDispatch()
  const {percentage, currentPage, searchProducts, searchMetadata} = useSelector(state => state)

  const handleEdit = async() => {
    setNewPrice(product.sale_price)
    setIsEdit(true)
  }

  const handleChange = (e) => {
    setNewPrice(e.target.value)
  }

  const handleSave = async() => {
    setIsLoading(true)
    const regularPrice = parseInt(newPrice) + (newPrice*(percentage/100))
    await updateAddProduct(product.id, {sale_price: `${newPrice}`, regular_price: `${regularPrice}`})
    if(searchProducts.length > 0) await dispatch(getProductsBySearch(searchMetadata.searchStr, searchMetadata.tag))
    else if(isSelect == 0) await dispatch(getCotProducts(currentPage))
    else await dispatch(getCotProductsByCat(currentPage, isSelect))
    setIsLoading(false)
    setIsEdit(false)
  }

  const handleDelete = async() => {
    setIsLoading(true)
    const data = product.tags.filter(el => el.name !== 'cot')
    await updateAddProduct(product.id, {sale_price: '', regular_price: '', tags: [...data, {id: 609, name: 'nan', slug: 'nan'}]})
    await dispatch(getCotProducts(currentPage))
    setIsLoading(false)
  }

  const handleAlertDelete = async() => {
    const confirmAction = window.confirm('¿Seguro que desea eliminar este producto del cotizador?')
    if(confirmAction) {
      await handleDelete()
    }
  }

  return (
    <>
    <div className={styles.CardCot}>
      {product.pause === true && <div className={styles.paused} />}
      <div className={styles.cardCtn}>
        <div className={styles.productImageCtn}>
          <img className={styles.productImage} src={product.images[0].src} alt="integrar producto" />
        </div>
        <div className={styles.info}>
          <p className={styles.title}>{product.name}</p>
          <div>
            <span className={styles.categoriesName}>{product.categories[0].name}</span>
          </div>
          <p className={styles.price}>Cliente final: ${Intl.NumberFormat('es-CO').format(parseInt(product.regular_price))}</p>
          {isEdit ? 
            <div className={styles.percCtn}>
              <Input
                styl='light'
                placeholder='Ingrese el nuevo precio'
                name='sale_price'
                value={newPrice}
                required={true}
                type='number'
                handleChange={handleChange} />
              <Percent percentage={parseInt(((parseInt(product.regular_price) * 100) / parseInt(product.sale_price)) - 100)} />
            </div>
            :
            <p className={styles.price}>Cliente mayorista: ${Intl.NumberFormat('es-CO').format(parseInt(product.sale_price))}</p>
          }
          <a href={product.permalink} target='_blank' rel="noreferrer">Descripción completa del producto...</a>
        </div>
        <div className={styles.right}>
          <div className={styles.addButton}>
            {!isEdit ? <FaEdit onClick={handleEdit}/> : <FaSave onClick={handleSave} />}
            <BsTrashFill onClick={handleAlertDelete} />
          </div>
        </div>
      </div>
      <div className={styles.descriptionSeparator}>
        <IoMdArrowDropdown className={styles.more} onClick={() => setViewDescription(!viewDescription)} />
        {viewDescription &&  
        <div className={styles.description}>
          <div dangerouslySetInnerHTML={{__html: product.short_description}} ></div>
        </div>
        }
      </div>
    </div>
    </>
  )
}

export default CardCot