import React from 'react'
import Input from '../../components/Input/Input'
import styles from './Profile.module.css'
import { IoEye, IoEyeOff } from 'react-icons/io5'
import { useState } from 'react'
import { changePass } from '../../redux/actions'
import Loader from '../../components/Loader/Loader'
import { useSelector } from 'react-redux'

function Profile() {
  const [isLoading, setIsLoading] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [credentials, setCredentials] = useState({currentPass: '', password: '', rePassword: ''})

  const {currentUser} = useSelector(state => state)


  const handleChange = (e) => {
    const {name, value} = e.target
    setCredentials({...credentials, [name]: value})
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const {currentPass, password, rePassword} = credentials
    if(currentPass === password) {
      alert('La nueva contraseña no puede ser igual a la anterior')
    } else if(password !== rePassword) {
      alert('Las contraseñas no coinciden, por favor verifique')
    } else if(password.length < 6) {
      alert('La contraseña debe contener mínimo 6 caracteres')
    } else {
      setIsLoading(true)
      const res = await changePass(currentPass, password)
      setIsLoading(false)
      if(res.status === 200) {
        alert('Contraseña cambiada con éxito')
        setCredentials({currentPass: '', password: '', rePassword: ''})
      } else {
        alert(res.errorMessage.split('Error ').pop())
      }
    }
  }

  return (
    <div className={styles.Profile}>
      <div className={styles.info}>
        <h2>Mis Datos</h2>
        <p>{currentUser.email}</p>
        <p>{currentUser.name} {currentUser.lastname}</p>
        <p>{currentUser.phoneNumber}</p>
      </div>
      <h4>Cambiar contraseña</h4>
      <form onSubmit={handleSubmit}>
        <div className={styles.passContainer}>
          <Input 
            styl='light' 
            type={showPass ? 'text' : 'password' }
            placeholder='Contraseña actual'
            name='currentPass'
            value={credentials.currentPass}
            required={true}
            handleChange={handleChange} />
          {showPass ? <IoEyeOff onClick={() => setShowPass(!showPass)} /> : 
            <IoEye onClick={() => setShowPass(!showPass)} />
          }
        </div>
        <div className={styles.passContainer}>
          <Input 
            styl='light' 
            type={showPass ? 'text' : 'password' }
            placeholder='Nueva contraseña'
            name='password'
            value={credentials.password}
            required={true}
            handleChange={handleChange} />
          {showPass ? <IoEyeOff onClick={() => setShowPass(!showPass)} /> : 
            <IoEye onClick={() => setShowPass(!showPass)} />
          }
        </div>
        <div className={styles.passContainer}>
          <Input 
            styl='light' 
            type={showPass ? 'text' : 'password' }
            placeholder='Repita nueva contraseña'
            name='rePassword'
            value={credentials.rePassword}
            required={true}
            handleChange={handleChange} />
          {showPass ? <IoEyeOff onClick={() => setShowPass(!showPass)} /> : 
            <IoEye onClick={() => setShowPass(!showPass)} />
          }
        </div>
        <Input 
          styl='dark' 
          type='submit' 
          value='cambiar contraseña' />
      </form>
      {isLoading && <Loader />}
    </div>
  )
}

export default Profile