import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Main from '../Main/Main'
import NotFound from '../NotFound/NotFound'
import Profile from '../Profile/Profile'
import styles from './Dashboard.module.css'
import Header from '../../components/Header/Header'
import AsideMenu from '../../components/AsideMenu/AsideMenu'
import Loader from '../../components/Loader/Loader'
import AddUser from '../../components/AddUser/AddUser'
import EditUser from '../../components/EditUser/EditUser'
import { useSelector } from 'react-redux'
import { logout } from '../../redux/actions'

function Dashboard() {
  const [isSelect, setIsSelect] = useState(0)
  const [labelActive, setLabelActive] = useState(1)
  const [authorized, setAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {currentUser} = useSelector(state => state)

  useEffect(() => { 
    if(currentUser.email !== 'cuervo357@gmail.com' && currentUser.email !== 'obandoramirez@yahoo.es') logout() 
    else setAuthorized(true) 
  }, []) // eslint-disable-line

  return (
    <>
    {!authorized ? <Loader /> : 
    <div className={styles.Dashboard}>
      <AsideMenu isSelect={isSelect} setIsSelect={setIsSelect} setLabelActive={setLabelActive} setIsLoading={setIsLoading}  />
      <div className={styles.dashboardCtn}>
        <Header />
        <Routes>
          <Route path='/' element={<Main 
            setIsSelect={setIsSelect} 
            isSelect={isSelect} 
            labelActive={labelActive} 
            setLabelActive={setLabelActive}
            setIsLoading={setIsLoading}
            isLoading={isLoading} />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/agregar-usuario' element={<AddUser />} />
          <Route path='/editar-usuario/:id' element={<EditUser />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div> 
    </div>}
    </>
  )
}

export default Dashboard