import React, { useEffect, useState } from 'react'
import styles from './Main.module.css'
import { FaCalculator, FaDatabase, FaUsers } from "react-icons/fa"
import Products from '../../components/Products/Products'
import CotProducts from '../../components/CotProducts/CotProducts'
import { useDispatch } from 'react-redux'
import { getCotProducts, getUsers, resetSearch } from '../../redux/actions'
import Users from '../../components/Users/Users'

function Main({
  setIsSelect, 
  isSelect, 
  labelActive, 
  setLabelActive,
  setIsLoading,
  isLoading
}) {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getCotProducts())
  }, []) // eslint-disable-line


  const handleLabel = async(id) => {
    dispatch(resetSearch())
    setLabelActive(id)
    if(id === 1 || id === 3) {
      setIsSelect(0)
    }
  }

  return (
    <div className={styles.Main}>
      <div className={styles.iconsBar}>
        <FaDatabase  onClick={() => handleLabel(1)} className={`${styles.label} ${styles.label1} ${labelActive === 1 ? styles.labelActive : styles.labelOff}`} />
        <FaCalculator  onClick={() => handleLabel(2)} className={`${styles.label} ${styles.label1} ${labelActive === 2 ? styles.labelActive : styles.labelOff}`} />
        <FaUsers  onClick={() => handleLabel(3)} className={`${styles.label} ${styles.label1} ${labelActive === 3 ? styles.labelActive : styles.labelOff}`} />
      </div>
      <div className={styles.labelCtn}>
        {labelActive === 1 ? 
        <Products setIsSelect={setIsSelect} />
        : labelActive === 2 ?
        <CotProducts setIsSelect={setIsSelect} isSelect={isSelect} setIsLoading={setIsLoading} isLoading={isLoading} />
        : 
        <Users />
        }
      </div>
    </div>
  )
}

export default Main