import React from 'react'
import styles from './Loader.module.css'

function Loader({size = 50}) {
  return (
    <div className={styles.loadingContainer}>
      <div className={`${styles.loadingSpinner}`} style={{width: size, height: size}}/>
    </div>
  )
}

export default Loader