import React, { useRef, useState } from 'react'
import styles from './CardProduct.module.css'
import { BsZoomIn, BsCurrencyDollar } from "react-icons/bs"
import { IoMdArrowDropdown } from "react-icons/io"
import { SiAddthis } from "react-icons/si"
import Loader from '../Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getCotProducts, updateAddProduct, updateSearch } from '../../redux/actions'

function CardProduct({product}) {
  const [zoomImage, setZoomImage] = useState(false)
  const [viewDescription, setViewDescription] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef()
  const dispatch = useDispatch()
  const { percentage } = useSelector(state => state)

  const handleAddProduct = async() => {
    if(inputRef.current.value.length) {
      setIsLoading(true)
      const data = product.tags.filter(el => el.name !== 'nan')
      await updateAddProduct(product.id, {sale_price: `${parseInt(inputRef.current.value)}`,regular_price: `${parseInt(inputRef.current.value) + (inputRef.current.value*(percentage/100))}`, tags: [...data, {id: 565, name:  'cot', slug: 'cot'}]})
      inputRef.current.value = ''
      dispatch(updateSearch(product.id))
      setIsLoading(false)
      dispatch(getCotProducts())
    } else {
      alert('Debe indicar un precio')
    }
  }

  return (
    <>
     {isLoading && <Loader />}
    <div className={styles.CardProduct}>
      {zoomImage && <img className={styles.imageZoom} src={product.image} alt="" onClick={() => setZoomImage(false)} />}
      <div className={styles.cardCtn}>
        <div className={styles.productImageCtn}>
          <img className={styles.productImage} src={product.images[0].src} alt="integrar producto" />
          <div className={styles.imageHover} onClick={() => setZoomImage(true)}>
            <BsZoomIn />
          </div>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>{product.name}</p>
          <div>
            <span className={styles.categoriesName}>{product.categories[0].name}</span>
          </div>
          <a href={product.permalink} target='_blank' rel="noreferrer">Descripción completa del producto...</a>
        </div>
        <div className={styles.addButton}>
          <div className={styles.input}>
            <BsCurrencyDollar />
            <input ref={inputRef} type="number" defaultValue={product.price} placeholder='Precio mayorista' />
          </div>
          <SiAddthis onClick={handleAddProduct} />
        </div>
      </div>
      <div className={styles.descriptionSeparator}>
        <IoMdArrowDropdown className={styles.more} onClick={() => setViewDescription(!viewDescription)} />
        {viewDescription &&  
        <div className={styles.description}>
          <div dangerouslySetInnerHTML={{__html: product.short_description}} ></div>
        </div>
        }
      </div>
    </div>
    </>
  )
}

export default CardProduct