import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import Login from './pages/Login/Login'
import NotFound from './pages/NotFound/NotFound'
import { useEffect } from 'react'
import { isLogged } from './redux/actions'

function App() {

  const {currentUser} = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(isLogged())
  }, []) // eslint-disable-line

  return (
    <div>
      <Routes>
        <Route path='/' element={!currentUser ? <Login /> : <Navigate to='/admin' />} />
        <Route path='/admin/*' element={currentUser ? <Dashboard /> : <Navigate to='/' />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App
