import { useEffect } from 'react'
import { useState } from 'react'
import styles from './Pagination.module.css'
import {BsArrowLeftSquareFill, BsArrowRightSquareFill} from 'react-icons/bs'
import {useDispatch, useSelector} from 'react-redux'
import { getCotProducts, getCotProductsByCat, pagination } from '../../redux/actions'

function Pagination({products, setIsLoading, isSelect}) {

  // useEffect(() => {
  //   dispatch(pagination(1))
  // }, [isSelect])

  const dispatch = useDispatch()
  const {currentPage, searchProducts} = useSelector(state => state)

  const prevPage = async() => {
    if(currentPage > 1) {
      setIsLoading(true)
      if(isSelect == 0) {
        await dispatch(getCotProducts(currentPage-1))
      } else {
        await dispatch(getCotProductsByCat(currentPage-1, isSelect))
      }
      setIsLoading(false)
      dispatch(pagination(currentPage - 1))
    }
  }

  const nextPage = async() => {
    if(products.length === 20) {
      setIsLoading(true)
      if(isSelect == 0) {
        await dispatch(getCotProducts(currentPage+1))
      } else {
        await dispatch(getCotProductsByCat(currentPage+1, isSelect))
      }
      setIsLoading(false)
      dispatch(pagination(currentPage + 1))
    }
  }
  
  return (
    <div className={styles.Pagination}>
      <div onClick={prevPage} className={currentPage <= 1 ? styles.arrowCtnDisabled : styles.arrowCtn}>
        <BsArrowLeftSquareFill />
        <span>Anterior</span>
      </div>
      <p>{currentPage}</p>
      <div onClick={nextPage} className={products.length === 20 ? styles.arrowCtn : styles.arrowCtnDisabled}>
        <span>Siguiente</span>
        <BsArrowRightSquareFill />
      </div>
    </div>
  )
}

export default Pagination