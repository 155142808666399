import React, { useState } from 'react'
import { FaPowerOff } from "react-icons/fa"
import { IoMdArrowDropdown } from "react-icons/io"
import styles from './Header.module.css'
import logo from '../../assets/logo_large.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { logout } from '../../redux/actions'

function Header() {
  const [showButton, setShowButton] = useState(false)

  const navigate = useNavigate()
  const {currentUser} = useSelector(state => state)

  const handleLogout = () => {
    logout()
  }

  const goToProfile = () => {
    setShowButton(false)
    navigate('/admin/profile')
  }

  return (
    <div className={styles.Header}>
      <div className={styles.right}>
        <img src={logo} alt="logo integrar" onClick={() => navigate('/admin')} />
      </div>
      <div className={styles.left}>
        <IoMdArrowDropdown className={styles.arrow} onClick={() => setShowButton(!showButton)}/>
        <p>{currentUser.name} {currentUser.lastname}</p>
        {showButton && <span className={styles.profileButton} onClick={goToProfile}>Mi perfil</span>}
        <FaPowerOff onClick={handleLogout} className={styles.buttonOff} />
      </div>
    </div>
  )
}

export default Header