import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addUser} from '../../redux/actions'
import styles from './AddUser.module.css'
import Input from '../Input/Input'
import Loader from '../Loader/Loader'
import Title from '../Title/Title'

function AddUser() {
  const [isLoading, setIsLoading] = useState(false)
  const [isUser, setIsUser] = useState({
    name: '',
    lastname: '',
    email: '', 
    phoneNumber: '',
    password: '', 
    rePassword: ''
  })
  
  const navigate = useNavigate()

  const handleChange = (e) => {
    const {name, value} = e.target
    setIsUser({...isUser, [name]: value})
  }

  const handleAdd = async(e) => {
    e.preventDefault()
    const {name, lastname, email, phoneNumber, password, rePassword} = isUser
    if(password !== rePassword) {
      alert('Las contraseñas no coinciden, por favor verifique')
    } else if(password.length < 6) {
      alert('La contraseña debe contener mínimo 6 caracteres')
    } else {
      setIsLoading(true)
      const res = await addUser(email.toLowerCase(), password, name, lastname, phoneNumber)
      setIsLoading(false)
      if(res.status === 200) {
        alert('Usuario creado con exito')
        setIsUser({name: '', lastname:'', email: '', phoneNumber: '', password: '', rePassword: ''})
        navigate('/admin')
      } else {
        alert(res.errorMessage.split('Error ').pop())
      }
    }
  }

  const handleCancel = () => {
    navigate('/admin')
  }

  return (
    <div className={styles.AddUser}>
      <Title title='Crear nuevo usuario' />
      <form onSubmit={handleAdd}>
        <Input 
          styl='dark' 
          placeholder='Nombres' 
          name='name'
          value={isUser.name}
          required={true}
          type='text'
          handleChange={handleChange} />
        <Input 
          styl='dark' 
          placeholder='Apellidos' 
          name='lastname'
          value={isUser.lastname}
          required={true}
          type='text'
          handleChange={handleChange} />
        <Input 
          styl='dark' 
          placeholder='Correo' 
          name='email'
          value={isUser.email}
          required={true}
          type='email'
          handleChange={handleChange} />
        <Input 
          styl='dark' 
          placeholder='Teléfono' 
          name='phoneNumber'
          value={isUser.phoneNumber}
          required={true}
          type='number'
          handleChange={handleChange} />
        <Input 
          styl='dark' 
          placeholder='Contraseña' 
          name='password'
          value={isUser.password}
          required={true}
          type='password'
          handleChange={handleChange} />
        <Input 
          styl='dark' 
          placeholder='Repetir contraseña' 
          name='rePassword'
          value={isUser.rePassword}
          required={true}
          type='password'
          handleChange={handleChange} />
        <div className={styles.buttonsAdd}>
          <Input 
            styl='dark' 
            value={'Crear'}
            type='submit' />
          <button onClick={handleCancel} className={styles.cancelBtn}>Cancelar</button>
        </div>
      </form>
      {isLoading && <Loader />}
    </div>
  )
}

export default AddUser