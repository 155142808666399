import React from 'react'
import { useDispatch } from 'react-redux'
import { percent } from '../../redux/actions'
import {FaPercent} from 'react-icons/fa'
import styles from './Percent.module.css'

function Percent({percentage}) {

  const dispatch = useDispatch()

  const handleChange = (e) => {
    dispatch(percent(parseInt(e.target.value)))
  }

  return (
    <div className={`${styles.input} ${styles.inputPerc}`}>
      <FaPercent />
      <input min={0} max={100} type="number" defaultValue={percentage} placeholder='Porcentaje mayoristas' onChange={handleChange} />
    </div>
  )
}

export default Percent