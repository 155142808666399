import React from 'react'
import SearchBar from '../SearchBar/SearchBar'
import styles from './Products.module.css'
import { useSelector } from 'react-redux'
import CardProduct from '../CardProduct/CardProduct'
import Percent from '../Percent/Percent'
import Title from '../Title/Title'

function Products({setIsSelect}) {

  const {searchProducts, percentage} = useSelector(state => state)

  return (
    <div className={styles.Products}>
      <SearchBar tag='609' setIsSelect={setIsSelect}  />
      <Title title={searchProducts.length ? `${searchProducts.length} productos encontrados` : 'Productos disponibles para el administrador'} />
      <Percent percentage={percentage} />
      <div className={styles.cardContainer}>
        
        {searchProducts.length ?  searchProducts.map((el, i) => (
          <CardProduct key={i} product={el} />
        ))
      :
      <p className={styles.empty}>Inicie una búsqueda...</p>  
      }
      </div>
      
    </div>
  )
}

export default Products