import React from 'react'
import styles from './Input.module.css'

function Input({
  styl = 'light', 
  type = 'text', 
  placeholder = '', 
  name = '', 
  value = '', 
  required = false, 
  disabled = false,
  handleChange = () => {}
}) {

  return (
    <input 
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      required={required}
      disabled={disabled}
      onChange={handleChange}
      className={`${styles[styl]} ${styles.standard} ${type === 'submit' && styles.btnSubmit}`} />
  )
}

export default Input