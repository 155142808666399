import { useDispatch, useSelector } from 'react-redux'
import styles from './CotProducts.module.css'
import CardCot from '../CardCot/CardCot'
import Loader from '../Loader/Loader'
import Title from '../Title/Title'
import Pagination from '../Pagination/Pagination'
import SearchBar from '../SearchBar/SearchBar'
import { useEffect } from 'react'
import { getProductsBySearch } from '../../redux/actions'

function CotProducts({isSelect, isLoading, setIsLoading,setIsSelect}) {

  const {products, productsByCat, searchProducts} = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    (async() => {
      setIsLoading(true)
      await dispatch(getProductsBySearch())
      setIsLoading(false)
    })()
    
  }, [])

  return (
    <div className={styles.CotProducts}>
      {isLoading ? <Loader /> :
      <>
      <h3 className={styles.title}>Productos en el cotizador</h3>
      {<SearchBar tag='565' setIsSelect={setIsSelect} />}
      <Title title={
        isSelect === 16 ? 'Cámaras de seguridad'
        : isSelect === 80 ? 'Kits de seguridad' 
        : isSelect === 18 ? 'DVR'
        : isSelect === 19 ? 'Discos duros'
        : isSelect === 15 ? 'Accesorios'  
        : 'Todos los productos'} />
        {/* {isSelect == 0 ? <Pagination products={products} setIsLoading={setIsLoading} isSelect={isSelect} /> : null} */}
        {isSelect != 0 ? 
          <Pagination 
            products={productsByCat} 
            setIsLoading={setIsLoading} 
            isSelect={isSelect} /> 
        : null}
      <div className={styles.cardsCtn}>
        {searchProducts.length ? searchProducts.map((el, i) => (
          <CardCot key={i} product={el} setIsLoading={setIsLoading} isSelect={isSelect} />
        ))
        :isSelect == 0 ? products.map((el, i) => (
          <CardCot key={i} product={el} setIsLoading={setIsLoading} isSelect={isSelect} />
        ))
        :
        productsByCat.map((el, i) => (
          <CardCot key={i} product={el} setIsLoading={setIsLoading} isSelect={isSelect} />
        ))}
      </div>
      {/* {isSelect === 0 && products.length === 20 ? <Pagination products={products} setIsLoading={setIsLoading} isSelect={isSelect} /> : null} */}
      {isSelect != 0 && 
        (searchProducts.length ? (searchProducts.length === 20) : productsByCat.length === 20) 
      ? 
      <Pagination 
        products={productsByCat} 
        setIsLoading={setIsLoading} 
        isSelect={isSelect} /> 
      : null}
      </>}
      </div>
  )
}

export default CotProducts