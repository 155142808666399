import React from 'react'
import styles from './AsideMenu.module.css'
import { TbDeviceCctv } from "react-icons/tb"
import { MdFiberDvr } from "react-icons/md"
import { FiHardDrive } from "react-icons/fi"
import { GiJackPlug, GiMeepleGroup } from "react-icons/gi"
import { FaUsers } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { getCotProductsByCat, pagination, resetSearch } from '../../redux/actions'
import { useEffect } from 'react'

function AsideMenu({isSelect, setIsSelect, setLabelActive, setIsLoading}) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pagination(1))
  }, [isSelect])

  const handleSelect = async(id) => {
    dispatch(resetSearch())
    if(id === 1) {
      setLabelActive(3)
      setIsSelect(0)
      navigate('/admin')
    } else if(id !== 0) {
      if(id !== isSelect) {
        setLabelActive(2)
        setIsLoading(true)
        await dispatch(getCotProductsByCat(1, id))
        setIsSelect(id)
        setIsLoading(false)
        navigate('/admin')
      }
    } else {
      setLabelActive(3)
      setIsSelect(0)
      navigate('/admin')
    }
  }

  return (
    <div className={styles.AsideMenu}>
      <div className={isSelect === 1 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(1)}>
        <FaUsers className={styles.icons} />
      </div>
      <div className={isSelect === 16 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(16)}>
        <TbDeviceCctv className={styles.icons} />
      </div>
      <div className={isSelect === 80 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(80)}>
        <GiMeepleGroup className={styles.icons} />
      </div>
      <div className={isSelect === 18 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(18)}>
        <MdFiberDvr className={styles.icons} />
      </div>
      <div className={isSelect === 19 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(19)}>
        <FiHardDrive className={styles.icons} />
      </div>
      <div className={isSelect === 15 ? styles.iconCtnSelect : styles.iconCtn} onClick={() => handleSelect(15)}>
        <GiJackPlug className={styles.icons} />
      </div>
    </div>
  )
}

export default AsideMenu