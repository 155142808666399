import React, { useRef, useState } from 'react'
import styles from './SearchBar.module.css'
import { BsSearch } from "react-icons/bs"
import { useDispatch } from 'react-redux'
import { getProductsBySearch, pagination } from '../../redux/actions'
import Loader from '../Loader/Loader'

function SearchBar({tag, setIsSelect}) {
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef()
  const dispatch = useDispatch()

  const handleSearch = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    await dispatch(getProductsBySearch(inputRef.current.value, tag, 1))
    setIsLoading(false)
    setIsSelect(0)
    // inputRef.current.value = ''
    dispatch(pagination(1))
  }

  return (
    <>
      <form onSubmit={handleSearch} className={styles.SearchBar}>
        <input minLength={3} ref={inputRef} className={styles.input} type="text" placeholder='Buscar productos...' required/>
        <BsSearch onClick={handleSearch} className={styles.BsSearch}/>
      </form>
      {isLoading && <Loader /> }
    </>
  )
}

export default SearchBar