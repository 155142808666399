const initialState = {
  searchProducts: [],
  searchMetadata: {searchStr: '', tag: ''},
  products: [],
  productsByCat: [],
  currentUser: null,
  users: [],
  user: null,
  percentage: 15,
  currentPage: 1,
};

const adminReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case "GET_CURRENT_USER":
      return { ...state, currentUser: actions.payload };
    case "GET_ALL_USERS":
      return { ...state, users: actions.payload };
    case "GET_USER_BY_ID":
      return { ...state, user: actions.payload };
    case "PRODUCTS_SEARCH":
      return { ...state, searchProducts: actions.payload.search, searchMetadata: {searchStr: actions.payload.string, tag: actions.payload.tag} };
    case "RESET_SEARCH":
      return { ...state, searchProducts: [], searchMetadata: {searchStr: '', tag: ''} };
    case "PRODUCTS":
      return { ...state, products: actions.payload };
    case "PRODUCTS_BY_CAT":
      return { ...state, productsByCat: actions.payload };
    case "PERCENTAGE":
      return { ...state, percentage: actions.payload };
    case "PRODUCTS_SEARCH_UPDATE":
      const update = state.searchProducts.filter(
        (el) => el.id !== actions.payload
      );
      return { ...state, searchProducts: update };
    case "PAGINATION":
      return { ...state, currentPage: actions.payload };
    default:
      return state;
  }
};

export default adminReducer;
